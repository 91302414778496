import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useUIDSeed } from 'react-uid'

import { NavigationActions, SearchActions } from '@hmn/rtl-web-core/actions'
import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { useGTM, useListElastic } from '@hmn/rtl-web-core/hooks'

import { articleCardVariants } from '@hmn/rtl-zena-ui/components/Article/components/Card'
import { CardsBlock } from '@hmn/rtl-zena-ui/components/CardsBlock'
import { EntityCard } from '@hmn/rtl-zena-ui/components/EntityCard'
import { ErrorPageHead } from '@hmn/rtl-zena-ui/components/ErrorPage/components'

import ErrorLayout from '../layouts/ErrorLayout.component'

function FourOhFour() {
    const uid = useUIDSeed()
    const dispatch = useDispatch()

    const handleNavAction = useCallback(() => {
        dispatch(NavigationActions.toggle())
        dispatch(SearchActions.toggle())
    }, [dispatch])

    const { sendDataToGTM } = useGTM()

    useEffect(() => {
        sendDataToGTM({
            eventCategory: 'Error page'
        })
    }, [sendDataToGTM])

    const { data: zoneList } = useListElastic(`featured`, undefined, undefined, { method: 'GET' })

    const gtmDataArticles = {
        eventCategory: 'Related Content Card',
        eventAction: 'Najčitaniji članci',
        eventLabel: 'Below content',
        eventValue: 'Card content'
    }

    const lead =
        'Stranica koju ste tražili nije pronađena. ' +
        'Možda je uklonjena, možda joj se promijenio naziv ili je samo trenutno nedostupna.'

    return (
        <ErrorLayout>
            <Row variant="error.main">
                <Column variant="error.head">
                    <ErrorPageHead
                        label="Info"
                        title="Ops, nešto se dogodilo!"
                        lead={lead}
                        buttonHomepageTitle="Idi na naslovnicu"
                        buttonHomepageLink="/"
                        buttonSearchTitle="Pretraži žena.hr"
                        buttonSearchAction={handleNavAction}
                    />
                </Column>
                <Column variant="error.listing">
                    <CardsBlock title="Možda će te zanimati">
                        {zoneList?.map((item, index) => (
                            <EntityCard
                                item={item}
                                key={uid(item?.id || index)}
                                imageWidth={350}
                                imageHeight={525}
                                variant={articleCardVariants.GAMMA}
                                gtmData={gtmDataArticles}
                            />
                        ))}
                    </CardsBlock>
                </Column>
            </Row>
        </ErrorLayout>
    )
}

export default FourOhFour
