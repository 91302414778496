import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Footer from '@hmn/rtl-zena-ui/components/Footer/Footer.component'
import Header from '@hmn/rtl-zena-ui/components/Header/Header.component'

import style from './BaseLayout/BaseLayout.style'
import Content from './BaseLayout/Content.component'

const LayoutStyled = styled.main(props => ({ ...style(props) }))

function ErrorLayout({ children }) {
    return (
        <LayoutStyled>
            <Header errorPage />

            <Content>{children}</Content>

            <Footer />
        </LayoutStyled>
    )
}

ErrorLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default ErrorLayout
